<template>
  <v-container
    fluid
    class="text-justify"
  >
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">
          VOKAR NRW - Naturschutzrechtliches Vorkaufsrecht
        </h2>
        <p>
          Die Anwendung VOKAR unterstützt Notare und Behörden die gesetzlichen Vorschriften zu
          Vorkaufsrechten
          nach §
          74 Landesnaturschutzgesetz NRW (LNatSchG), die nicht im Grundbuch verzeichnet sind, zu
          vollziehen.
        </p>
        <p>
          Hierbei werden von VOKAR jede Einsichtnahme sowie rechtsverbindliche Auskünfte zu
          Vorkaufsrechten
          des Landes
          NRW für Notare aus dem gesamten Bundesgebiet erzeugt, gespeichert und zum Download zur Verfügung
          gestellt.
        </p>
        <p>
          Datengrundlage bildet das amtliches Verzeichnis derjenigen Grundstücke in Nordrhein-Westfalen,
          für
          die ein
          Vorkaufsrecht nach § 74 Landesnaturschutzgesetz NRW (LNatSchG) besteht.
        </p>
        <p>
          Die zuständigen Behörden für die Wahrnehmung der genannten Vorkaufsrechte in NRW sind die
          Bezirksregierungen.
          Die das öffentliche Verzeichnis führende Behörde ist das Landesamt für Natur, Umwelt und
          Klima
          NRW (LANUK).
        </p>

        <div class="border-red pa-3 mb-4">
          <p>
            Die Anmeldung an VOKAR erfolgt über jeweils eigene Authentifizierungsdienste (SAFE-Systeme),
            die
            an
            VOKAR angebunden sind.
          </p>
          <p class="ma-0">
            Zur Anmeldung werden Sie auf eine externe Webseite umgeleitet, welche durch das jeweilige
            SAFE-System
            vorgegeben wird. Nach erfolgreicher Anmeldung auf dieser Seite werden Sie automatisch zur
            Anwendung
            VOKAR zurückgeleitet.
          </p>
        </div>

        <v-row no-gutters justify="center">
          <v-col md="8">
            <v-card tile>
              <v-card-title class="primary white--text text-body-1 px-2 py-1">
                <div class="text-subtitle-1">Anmeldung</div>
                <v-spacer></v-spacer>
                <div class="text-subtitle-1">VOKAR NRW</div>
              </v-card-title>

              <v-row no-gutters :class="actionsClass">
                <v-col
                  v-for="idp in idps"
                  :key="idp.value.toLowerCase()"
                >
                  <v-btn
                    block
                    color="grey lighten-2"
                    depressed
                    :min-width="165"
                    style="font-size: 13px"
                    tile
                    @click="login(idp.value)"
                  >
                    {{ idp.text }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VActionRow from '../components/VActionRow';

import { API } from '../config';
import { replaceLocation } from '../common/lang';

export default {
  components: {
    VActionRow
  },
  data () {
    return {
      idps: [
        {
          value: 'bnotk',
          text: 'Notar'
        },
        {
          value: 'public',
          text: 'Landesverwaltung'
        }
      ]
    };
  },
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown;
    },
    actionsClass () {
      return {
        'pa-2': true,
        'login__actions': !this.smAndDown,
        'login__actions-mobile': this.smAndDown
      };
    }
  },
  methods: {
    login (idp) {
      const relayState = this.$route.query.relayState
        ? this.$route.query.relayState
        : window.location;

      const redirectUrl = `${API.login.url}/${idp}?${API.login.targetUrlParameter}=${relayState}`;
      replaceLocation(redirectUrl);
    }
  }
};
</script>

<style scoped>
.border-red {
  border: 2px solid red;
}

.login__actions-mobile {
  flex-direction: column;
}

.login__actions >>> .col.col + .col {
  margin-left: 8px !important;
  margin-top: 0 !important;
}

.login__actions-mobile >>> .col.col + .col {
  margin-left: 0 !important;
  margin-top: 8px !important;
}
</style>
